import Chance from 'chance'

import BinBatch from '@/ViewContainers/models/BinBatch'

const chance = new Chance()

export const binBatchStub = (): BinBatch => ({
  data: {
    bins: [
      {
        id: chance.integer(),
        name: chance.string(),
        barcode: chance.string(),
        binType: {
          slug: chance.string(),
          name: chance.string(),
        },
        courier: {
          slug: chance.string(),
          name: chance.string(),
        },
      },
    ],
  },
})
