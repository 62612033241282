import Package, { PackageMeasurements, PackageMeasurementsMetadata } from '@/core/models/Package'
import { Repack } from '@/core/models/Repack'
import PackagesGateway from '@/core/ports/packages'
import { MeasureFormData } from '@/pages/PackagePage/MeasureForm'
import { BatteryInspectionFormData } from '@/pages/PackagePage/PackageInspection/BatteryInspection'
import { SearchFormData } from '@/pages/ScanPage/ScanPage'
import ReturnPackage from '@/returnPackage/models/ReturnPackage'

import { ConfirmFlagPayload, DismissFlagPayload, InspectionSource } from '../api/models/Package'
import { packageStub } from './stubs/Package'
import { packageMeasurementsStub } from './stubs/PackageMeasurements'
import { repackStub } from './stubs/Repack'

const packages = [packageStub()]

function scan(barcode: string): Promise<(Package | ReturnPackage)[]> {
  return new Promise((resolve) => setTimeout(() => resolve([{ ...packages[0] }]), 1000))
}

function markAsReceived(packageId: string, searchParams: SearchFormData): Promise<Package> {
  packages[0].warehouseState.value = 'received'
  return new Promise((resolve) => setTimeout(() => resolve({ ...packages[0] }), 1000))
}

function measureWeightDimensions(
  packageId: string,
  formData: MeasureFormData,
  metadata: PackageMeasurementsMetadata | undefined,
): Promise<Package> {
  packages[0].warehouseState.value = 'measured'
  return new Promise((resolve) => setTimeout(() => resolve({ ...packages[0] }), 1000))
}

function requestLabel(packageId: string): Promise<Package> {
  packages[0].warehouseState.value = 'label_generated'
  return new Promise((resolve) => setTimeout(() => resolve({ ...packages[0] }), 1000))
}

function printLabel(packageId: string): Promise<Package> {
  packages[0].warehouseState.value = 'ready_for_xray'
  return new Promise((resolve) => setTimeout(() => resolve({ ...packages[0] }), 1000))
}

function requireInspection(packageId: string): Promise<Package> {
  packages[0].warehouseState.value = 'dg_inspection_required'
  return new Promise((resolve) => setTimeout(() => resolve({ ...packages[0] }), 1000))
}

function requireDestroyLiquidate(packageId: string): Promise<Package> {
  packages[0].warehouseState.value = 'to_be_destroyed'
  return new Promise((resolve) => setTimeout(() => resolve({ ...packages[0] }), 1000))
}

function dismissFlag(packageId: string, payload: DismissFlagPayload): Promise<Package> {
  packages[0].warehouseState.value = 'xray_completed'
  return new Promise((resolve) => setTimeout(() => resolve({ ...packages[0] }), 1000))
}

function confirmFlag(packageId: string, payload: ConfirmFlagPayload): Promise<Package> {
  packages[0].warehouseState.value = 'dg_confirmed'
  packages[0].screeningFlags[0].status = 'confirmed'
  packages[0].screeningFlags[0].comments = payload.comments
  return new Promise((resolve) => setTimeout(() => resolve({ ...packages[0] }), 1000))
}

function submitBatteryInspection(
  packageId: string,
  formData: BatteryInspectionFormData,
): Promise<Package> {
  packages[0].warehouseState.value = 'battery_confirmed_compliant'
  return new Promise((resolve) => setTimeout(() => resolve({ ...packages[0] }), 1000))
}

function markForDestruction(packageId: string): Promise<Package> {
  packages[0].warehouseState.value = 'to_be_destroyed'
  return new Promise((resolve) => setTimeout(() => resolve({ ...packages[0] }), 1000))
}

function markForLiquidation(packageId: string): Promise<Package> {
  packages[0].warehouseState.value = 'to_be_liquidated'
  return new Promise((resolve) => setTimeout(() => resolve({ ...packages[0] }), 1000))
}

function printBarcode(packageId: string): Promise<void> {
  return new Promise((resolve) => setTimeout(() => resolve(), 1000))
}

function reprintLabel(packageId: string): Promise<void> {
  return new Promise((resolve) => setTimeout(() => resolve(), 1000))
}

function requestInspection(packageId: string, source: InspectionSource): Promise<Package> {
  return new Promise((resolve) => setTimeout(() => resolve({ ...packages[0] }), 1000))
}

function placeInBin(packageId: string, barcode: string): Promise<void> {
  return new Promise((resolve) => setTimeout(() => resolve(), 1000))
}

function getPackageMeasurements(): Promise<PackageMeasurements> {
  return new Promise((resolve) => setTimeout(() => resolve(packageMeasurementsStub()), 1000))
}

function resetToMeasureStep(packageId: string): Promise<Package> {
  return new Promise((resolve) => setTimeout(() => resolve({ ...packages[0] }), 1000))
}

function forceDestroyLiquidate(packageId: string): Promise<Package> {
  return new Promise((resolve) => setTimeout(() => resolve({ ...packages[0] }), 1000))
}
function markAsCourierReturn(packageId: string): Promise<Package> {
  return new Promise((resolve) => setTimeout(() => resolve({ ...packages[0] }), 1000))
}
function requestRepack(packageId: string, upc: string): Promise<Repack> {
  return new Promise((resolve) => setTimeout(() => resolve(repackStub()), 1000))
}
function skipRepack(packageId: string): Promise<void> {
  return new Promise((resolve) => setTimeout(() => resolve(), 1000))
}

function submitZendeskTickets(packageId: string, zendeskTicketId: string): Promise<void> {
  return new Promise((resolve) => setTimeout(() => resolve(), 1000))
}

function getPackageById(packageId: string): Promise<Package> {
  return new Promise((resolve) => setTimeout(() => resolve({ ...packages[0] }), 1000))
}
export default function createInMemoryPackagesGateway(): PackagesGateway {
  return {
    scan,
    markAsReceived,
    measureWeightDimensions,
    requestLabel,
    printLabel,
    requireInspection,
    requireDestroyLiquidate,
    dismissFlag,
    confirmFlag,
    submitBatteryInspection,
    markForDestruction,
    markForLiquidation,
    printBarcode,
    reprintLabel,
    requestInspection,
    placeInBin,
    getPackageMeasurements,
    resetToMeasureStep,
    forceDestroyLiquidate,
    markAsCourierReturn,
    requestRepack,
    skipRepack,
    submitZendeskTickets,
    getPackageById,
  }
}
