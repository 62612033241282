import { useQuery } from '@tanstack/react-query'

import { toastApiError } from '@/components/Toastify'
import { useGateways } from '@/contexts/gateways'

export default function useGetPrintQueueQuery() {
  const { binsGateway } = useGateways()

  const query = useQuery({
    queryKey: ['printQueue'],
    queryFn: () => binsGateway.getPrintQueue(),
    refetchInterval: 5000,
    onError: toastApiError,
  })

  return query
}
