import PrintQueue from '@/ViewContainers/models/PrintQueue'
import PrintQueueDataResponse from '../models/PrintQueue'

export function mapPrintQueueFromApi(printQueues: PrintQueueDataResponse[]): PrintQueue[] {
  return printQueues.map((printQueue) => ({
    id: printQueue.id,
    status: printQueue.status,
    createdAt: printQueue.created_at,
    printQueueItems: printQueue.print_queue_items.map((printQueueItem) => ({
      id: printQueueItem.id,
      printQueueId: printQueueItem.print_queue_dd,
      title: printQueueItem.title,
      status: printQueueItem.status,
      barcodeText: printQueueItem.barcode_text,
      barcodeLargeText: printQueueItem.barcode_large_text,
      barcodeNote: printQueueItem.barcode_note,
    })),
  }))
}
