import { BinTypesResponse } from '../models/Bin'
import BinType from '@/ViewContainers/models/BinType'

export function mapAvailableBinsTypeFromApi(binTypes: BinTypesResponse): BinType {
  return {
    binTypes: binTypes.bin_types.map((binType) => ({
      slug: binType.slug,
      name: binType.name,
    })),
    couriers: binTypes.couriers.map((binType) => ({
      slug: binType.slug,
      name: binType.name,
    })),
  }
}
