import Chance from 'chance'

import BinType from '@/ViewContainers/models/BinType'

const chance = new Chance()

export const binTypeStub = (): BinType => ({
  binTypes: [
    {
      slug: chance.string(),
      name: chance.string(),
    },
  ],
  couriers: [
    {
      slug: chance.string(),
      name: chance.string(),
    },
  ],
})
