import MenuItem from '@mui/material/MenuItem'
import Stack from '@mui/material/Stack'
import { useState } from 'react'

import Button from '@/components/Button'
import Dialog, { DialogProps } from '@/components/Dialog'
import Input from '@/components/Input'
import Select from '@/components/Select'
import BinDetails from '@/ViewContainers/models/BinDetails'
import useBinFloorLocationsQuery from '@/hooks/useBinFloorLocationsQuery'

import { useBin } from './BinProvider'

interface MoveBinEditFormData {
  floorLocationId: number | ''
}

export interface MoveBinFormData {
  floorLocationId: number
}

const defaultBinFormData: MoveBinEditFormData = {
  floorLocationId: '',
}

interface MoveBinDialogProps extends Omit<DialogProps, 'title'> {
  bin: BinDetails
}

export default function MoveBinDialog({ bin, ...props }: MoveBinDialogProps) {
  const { data: binFloorLocations = [] } = useBinFloorLocationsQuery()
  const { moveBin } = useBin()
  const [formData, setFormData] = useState<MoveBinEditFormData>({
    ...defaultBinFormData,
    floorLocationId: bin.floorLocation.id,
  })
  const [isLoading, setIsLoading] = useState(false)

  function updateFormData(updates: Partial<MoveBinEditFormData>) {
    setFormData({ ...formData, ...updates })
  }

  async function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault()
    try {
      await moveBin(formData as MoveBinFormData)
      props.onClose?.()
    } catch (reason) {
      setFormData({ ...defaultBinFormData, floorLocationId: bin.floorLocation.id })
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <Dialog title={`Move ${bin.name}`} {...props} maxWidth="xs" fullWidth>
      <form onSubmit={handleSubmit}>
        <Stack spacing={3}>
          <Input label="Bin Type" value={bin.typeName} disabled />
          <Select
            label="Floor Location"
            value={formData.floorLocationId}
            onChange={(e) => updateFormData({ floorLocationId: +e.target.value })}
          >
            {binFloorLocations.map((binFloorLocation) => (
              <MenuItem key={binFloorLocation.id} value={binFloorLocation.id}>
                {binFloorLocation.name}
              </MenuItem>
            ))}
          </Select>
          <Button
            type="submit"
            variant="contained"
            sx={{ alignSelf: 'center', height: 60 }}
            loading={isLoading}
          >
            confirm bin move
          </Button>
        </Stack>
      </form>
    </Dialog>
  )
}
