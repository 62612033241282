import Box from '@mui/material/Box'
import MuiButton, { ButtonProps as MuiButtonProps } from '@mui/material/Button'
import { useTheme } from '@mui/material/styles'

import Pulse from './Pulse'
import { Button, ButtonGroup, Input } from 'easyship-components'
import { Minus, Plus } from 'easyship-components/icons'
import { useState } from 'react'

interface QuantityInputProps {
  quantity: number
  onValueChange(quantity: number): void
  max?: number
}

export default function QuantityInput({
  onValueChange,
  max = 100,
  quantity = 0,
}: QuantityInputProps) {
  function handleMinus() {
    onValueChange(quantity - 1)
  }

  function handlePlus() {
    onValueChange(quantity + 1)
  }

  function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
    if (parseInt(e.target.value)) {
      onValueChange(parseInt(e.target.value))
    } else {
      onValueChange(0)
    }
  }

  return (
    <div className="flex items-center">
      <Button
        flat
        color="primary"
        leftIcon={<Minus />}
        onClick={handleMinus}
        disabled={quantity <= 0}
        type="button"
        name="minus"
        aria-label="minus"
      />
      <Input
        aria-label="quantity"
        name="quantity"
        status={quantity > max ? 'error' : 'default'}
        min={0}
        max={max}
        type="number"
        className="no-arrows mx-2 w-20 text-center"
        value={quantity.toString()}
        onChange={handleChange}
      />
      <Button
        flat
        color="primary"
        leftIcon={<Plus />}
        onClick={handlePlus}
        disabled={quantity >= max}
        type="button"
        name="plus"
        aria-label="plus"
      />
    </div>
  )
}
