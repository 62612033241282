import { PrintedQueue } from '@/ViewContainers/models/PrintedQueue'

import { PrintedQueueResponse } from '../models/PrintedQueue'

export function mapFromApi(printedQueue: PrintedQueueResponse): PrintedQueue {
  return {
    id: printedQueue.id,
    status: printedQueue.status,
    createdAt: printedQueue.created_at,
    updatedAt: printedQueue.updated_at,
    printQueueItems: printedQueue.print_queue_items.map((printQueueItem) => ({
      id: printQueueItem.id,
      printQueueId: printQueueItem.print_queue_dd,
      title: printQueueItem.title,
      status: printQueueItem.status,
      barcodeText: printQueueItem.barcode_text,
      barcodeLargeText: printQueueItem.barcode_large_text,
      barcodeNote: printQueueItem.barcode_note,
      createdAt: printQueueItem.created_at,
      updatedAt: printQueueItem.updated_at,
    })),
  }
}
