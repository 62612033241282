import Container from '@mui/material/Container'
import Stack from '@mui/material/Stack'
import { Button } from 'easyship-components'
import { Plus, PrintQueue as PrintQueueIcon } from 'easyship-components/icons'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router'

import AppBar from '@/components/AppBar'
import ScanInput from '@/components/ScanInput'
import Spacer from '@/components/Spacer'
import useBins from '@/core/useBins'
import { ProcessStage } from '@/ViewContainers/models/Bin'

import { NavigationStateParams as BinDetailsPageNavigationStateParams } from './BinDetailsPage/BinDetailsPage'
import ConfirmDeletePrintQueueDialog from './ConfirmDeletePrintQueueDialog'
import ContainerProcessStages from './ContainerProcessStages'
import useGetPrintQueueQuery from './hooks/useGetPrintQueueQuery'
import PrintQueue from './models/PrintQueue'
import NewContainerDialog from './NewContainerDialog'
import PrintQueueDrawer from './PrintQueueDrawer'

export default function ViewContainersPage() {
  const navigate = useNavigate()
  const { data: printQueue } = useGetPrintQueueQuery()
  const { getBinDetailsByBarcode, getBinProcessStages } = useBins()
  const [isNewBinDialogOpen, setIsNewBinDialogOpen] = useState(false)
  const [isPrintQueueOpen, setIsPrintQueueOpen] = useState(false)
  const [isConfirmDeleteDialogOpen, setIsConfirmDeleteDialogOpen] = useState(false)
  const [processStages, setProcessStages] = useState<ProcessStage[]>([])
  const [deleteQueue, setDeleteQueue] = useState<PrintQueue>()

  useEffect(() => {
    getBinProcessStages().then(setProcessStages)
  }, [])

  function handleScanChange(barcode: string) {
    getBinDetailsByBarcode(barcode).then((binDetails) => {
      const navigationState: BinDetailsPageNavigationStateParams = {
        binDetails,
      }

      navigate('/bin-details', { state: navigationState })
    })
  }

  function getPrintQueueById(id: number) {
    setIsPrintQueueOpen(false)
    setIsConfirmDeleteDialogOpen(true)
    setDeleteQueue(printQueue?.find((queue) => queue.id === id))
  }

  return (
    <>
      <AppBar title="Bin Module">
        <Spacer />
        <Button color="primary" flat onClick={() => navigate('/')}>
          home
        </Button>
      </AppBar>
      <Container sx={{ my: 3 }}>
        <Stack spacing={4}>
          <Stack direction="row" justifyContent="space-between">
            <ScanInput
              placeholder="Scan to see bin details"
              autoFocus
              sx={{ maxWidth: 230 }}
              onChange={handleScanChange}
            />
            <div className="flex items-center gap-4">
              <Button onClick={() => setIsNewBinDialogOpen(true)} leftIcon={<Plus />}>
                Create new containers
              </Button>
              <Button onClick={() => setIsPrintQueueOpen(true)} leftIcon={<PrintQueueIcon />}>
                print queue
              </Button>
            </div>
          </Stack>
          <ContainerProcessStages data={processStages} />
        </Stack>
      </Container>
      <NewContainerDialog open={isNewBinDialogOpen} onClose={() => setIsNewBinDialogOpen(false)} />
      <PrintQueueDrawer
        printQueue={printQueue as PrintQueue[]}
        openDrawer={isPrintQueueOpen}
        onClose={() => setIsPrintQueueOpen(false)}
        openConfirmDeleteDialog={getPrintQueueById}
      />
      <ConfirmDeletePrintQueueDialog
        open={isConfirmDeleteDialogOpen}
        printQueue={deleteQueue}
        onClose={() => setIsConfirmDeleteDialogOpen(false)}
      />
    </>
  )
}
