import axios, { AxiosResponse } from 'axios'

import Package from '../../core/models/Package'
import InspectionsGateway from '../../core/ports/inspections'
import { BatteryInspectionFormData } from '../../pages/PackagePage/PackageInspection/BatteryInspection'
import { mapFromApi } from './mappers/packageMapper'
import { CategorizePayload, ConfirmPayload, DismissPayload } from './models/Inspection'
import {
  BatteryCellPayload,
  BatteryInspectionPayload,
  InspectionCategoryDefined,
  PackageResponse,
} from './models/Package'

function dismiss(id: string, comments: string): Promise<Package> {
  return axios
    .post<PackageResponse, AxiosResponse<PackageResponse>, DismissPayload>(
      `/v1/inspections/${id}/dismiss`,
      { comments },
    )
    .then(({ data }) => mapFromApi(data.package))
}

function categorize(id: string, category: InspectionCategoryDefined): Promise<Package> {
  return axios
    .post<PackageResponse, AxiosResponse<PackageResponse>, CategorizePayload>(
      `/v1/inspections/${id}/set_category`,
      { category },
    )
    .then(({ data }) => mapFromApi(data.package))
}

function confirm(id: string, comments: string): Promise<Package> {
  return axios
    .post<PackageResponse, AxiosResponse<PackageResponse>, ConfirmPayload>(
      `/v1/inspections/${id}/confirm`,
      { comments },
    )
    .then(({ data }) => mapFromApi(data.package))
}

function classifyBattery(id: string, formData: BatteryInspectionFormData): Promise<Package> {
  return axios
    .post<PackageResponse, AxiosResponse<PackageResponse>, BatteryInspectionPayload>(
      `/v1/inspections/${id}/battery_classification`,
      {
        forms: formData.batteryFormsData.map((data) => ({
          item_id: data.itemId,
          item_description: data.itemDescription,
          battery_present: data.batteryPresent,
          battery_classification: data.classification || null,
          battery_classification_comment: data.classificationComment || null,
          requires_compliance_review: data.requiresComplianceReview ?? null,
          battery_packaging: data.packing || null,
          battery_type: data.type || null,
          batteries_or_cells:
            data.batteriesCells.length > 0
              ? data.batteriesCells.map<BatteryCellPayload>((batteryCell) => ({
                  lithium_content: batteryCell.lithiumContent || null,
                  watt_hours: batteryCell.wattHours || null,
                  total_weight: batteryCell.totalWeight.value,
                  weight_unit: batteryCell.totalWeight.unit,
                }))
              : [],
          is_equipment_and_battery_secured_from_movement: data.movementSecured ?? null,
        })),
        is_parcel_equipped_to_prevent_accidental_activation: formData.doesPreventAccident ?? null,
        is_parcel_packaged_in_cardboard: formData.isPackagedCardboard ?? null,
      },
    )
    .then(({ data }) => mapFromApi(data.package))
}

export default function createApiInspectionsGateway(): InspectionsGateway {
  return {
    dismiss,
    categorize,
    confirm,
    classifyBattery,
  }
}
