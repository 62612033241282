import { Alert, Button, Modal } from 'easyship-components'
import { CircleCross } from 'easyship-components/icons'
import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'

import QuantityInput from '@/components/QuantityInput'
import Tab from '@/components/Tab'

import { useCreateMultipleBinsMutation } from './hooks/useCreateMultipleBinsMutation'
import useGetAvailableBinsTypeQuery from './hooks/useGetAvailableBinsTypeQuery'
import { BinTypeDetails } from './models/BinType'

interface NewContainerProps {
  open: boolean
  onClose: () => void
}

export interface NewBinFormData {
  binTypeSlug: string
  courierSlug: string | null
  quantity: number
  displayName: string
}

export default function NewContainerDialog({ open, onClose }: NewContainerProps) {
  const { data: binsType } = useGetAvailableBinsTypeQuery()
  const createMultipleBinsMutation = useCreateMultipleBinsMutation()
  const [formData, setFormData] = useState<NewBinFormData[]>([])
  const [selectedTab, setSelectedTab] = useState<'courier' | 'other'>('courier')
  const otherContainer = binsType?.binTypes.filter((type) => type.slug !== 'courier')

  const tableOptions = selectedTab === 'courier' ? binsType?.couriers : otherContainer

  const totalQuantityPerType = formData.reduce((acc, data) => {
    if (selectedTab === 'courier') {
      return data.binTypeSlug === 'courier' ? acc + data.quantity : acc
    } else {
      return data.binTypeSlug !== 'courier' ? acc + data.quantity : acc
    }
  }, 0)

  const showErrorWhenSameBinOverMax = formData.some((item) => {
    if (selectedTab !== 'courier') {
      return item.binTypeSlug !== 'courier' && item.quantity > 20
    }
    return
  })

  const showMaxForCourierContainer = formData.some((item) => {
    if (selectedTab === 'courier') {
      return item.binTypeSlug === 'courier' && item.quantity > 100
    }
    return
  })

  function isFormValid() {
    if (totalQuantityPerType === 0) {
      return false
    }
    if (totalQuantityPerType > 200) {
      return false
    }
    return true
  }

  function updateFormData(updates: Partial<NewBinFormData>) {
    setFormData((prevData) =>
      updates.quantity === 0
        ? prevData.filter((item) =>
            selectedTab === 'courier'
              ? item.courierSlug !== updates.courierSlug
              : item.binTypeSlug !== updates.binTypeSlug,
          )
        : [
            ...prevData.filter((item) =>
              selectedTab === 'courier'
                ? item.courierSlug !== updates.courierSlug
                : item.binTypeSlug !== updates.binTypeSlug,
            ),
            updates as NewBinFormData,
          ],
    )
  }

  function handleQuantityChange(quantity: number, type: BinTypeDetails) {
    updateFormData({
      quantity: quantity,
      binTypeSlug: selectedTab === 'courier' ? 'courier' : type.slug,
      courierSlug: selectedTab === 'courier' ? type.slug : null,
      displayName: type.name,
    })
  }

  function handleRemoveBin(data: NewBinFormData) {
    setFormData((prevData) =>
      prevData.filter((item) =>
        selectedTab === 'courier'
          ? item.courierSlug !== data.courierSlug
          : item.binTypeSlug !== data.binTypeSlug,
      ),
    )
  }

  function handleCreateBins() {
    if (isFormValid()) {
      // Filter data by selectedTab and remove displayName
      const sanitizedFormData = formData.reduce(
        (acc, data) => {
          if (
            (selectedTab === 'courier' && data.binTypeSlug === 'courier') ||
            (selectedTab !== 'courier' && data.binTypeSlug !== 'courier')
          ) {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const { displayName, ...rest } = data
            acc.push(rest)
          }
          return acc
        },
        [] as Omit<NewBinFormData, 'displayName'>[],
      )

      createMultipleBinsMutation.mutate(
        { bins: sanitizedFormData },
        {
          onSuccess: (res) => {
            console.log('res', res)
            onClose()
            toast.success(`${res.data.bins.length} containers created`)
            toast.success(`${res.data.bins.length} label sent to print`)
          },
        },
      )
    }
  }

  useEffect(() => {
    const timerId = setTimeout(() => {
      setFormData([])
      setSelectedTab('courier')
    }, 300)
    return () => clearTimeout(timerId)
  }, [onClose])

  return (
    <Modal
      data-testid="new-container-dialog"
      open={open}
      title="Create Containers"
      onClose={onClose}
      dismissOnClickBackdrop
      dismissOnEscape
    >
      <Modal.Header title="Create Containers" closeButtonAriaLabel="Close modal" />
      <Modal.Content className="w-[90vw] pb-12 pt-3 lg:w-[1024px]">
        <div className="mb-8 flex border-b border-sky-500">
          <Tab
            title="Courier Containers"
            selected={selectedTab === 'courier'}
            onTabChange={() => setSelectedTab('courier')}
          />
          <Tab
            title="Other Containers"
            selected={selectedTab === 'other'}
            onTabChange={() => setSelectedTab('other')}
          />
        </div>

        <div className="grid grid-cols-1 gap-y-10 lg:grid-cols-3 lg:gap-10">
          <div className="col-span-2 w-full">
            <div className="mb-3 flex items-center justify-between">
              <div className="font-bold text-ink-500">
                {selectedTab === 'courier' ? 'Add courier containers:' : 'Add other containers:'}
              </div>
              <div className="italic text-ink-100">max 200 containers total</div>
            </div>
            <table className="w-full">
              <tbody>
                {tableOptions?.map((type, index) => (
                  <tr
                    key={`${type.slug}-${index}`}
                    className={`border border-sky-500 ${
                      formData.some((data) =>
                        selectedTab === 'courier'
                          ? data.courierSlug === type.slug
                          : data.binTypeSlug === type.slug,
                      )
                        ? 'bg-[#EDFBFA]'
                        : ''
                    }`}
                  >
                    <td className="w-full border-r border-sky-500  px-3 py-2">
                      <div className="flex items-center justify-between text-xl">
                        {type.name}{' '}
                        <span className="text-base italic text-ink-100">
                          max {selectedTab === 'courier' ? 100 : 20}
                        </span>
                      </div>
                    </td>
                    <td className="w-[190px] p-2">
                      <QuantityInput
                        max={selectedTab === 'courier' ? 100 : 20}
                        quantity={
                          formData.find((data) =>
                            selectedTab === 'courier'
                              ? data.courierSlug === type.slug
                              : data.binTypeSlug === type.slug,
                          )?.quantity as number
                        }
                        onValueChange={(quantity) => handleQuantityChange(quantity, type)}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div>
            <div className="mb-3 font-bold text-ink-500 ">
              You are creating the following containers:
            </div>
            <div className="border border-sky-500 p-6">
              {formData.length === 0 && (
                <div className="text-lg text-ink-100">Add containers to see them appear here</div>
              )}
              {formData.length > 0 && (
                <div>
                  {formData
                    .filter((data) =>
                      selectedTab === 'courier'
                        ? data.binTypeSlug === 'courier'
                        : data.binTypeSlug !== 'courier',
                    )
                    .map((data, index) => (
                      <div key={index} className="flex items-center justify-between">
                        <div className="text-lg">
                          <span className="text-xl">{data.quantity}</span> x {data.displayName}
                        </div>
                        <CircleCross
                          className="cursor-pointer fill-ink-100"
                          onClick={() => handleRemoveBin(data)}
                        />
                      </div>
                    ))}
                </div>
              )}
            </div>
            {showErrorWhenSameBinOverMax && (
              <Alert className="mt-6" severity="error">
                <div data-testid="error-containers">
                  Maximum limit of 20 of the same container exceeded.
                </div>
              </Alert>
            )}
            {showMaxForCourierContainer && (
              <Alert className="mt-6" severity="error">
                <div data-testid="error-containers">
                  Maximum limit of 100 of the same container exceeded.
                </div>
              </Alert>
            )}
            {totalQuantityPerType > 200 && (
              <Alert className="mt-6" severity="error">
                <div data-testid="error-containers">
                  Maximum limit of 200 containers exceeded. Please reduce the number of containers
                  to 100 or less.
                </div>
              </Alert>
            )}
            <Button
              loading={createMultipleBinsMutation.isLoading}
              type="submit"
              onClick={handleCreateBins}
              disabled={!isFormValid()}
              className="mt-6 w-full"
              color="primary"
            >
              create {totalQuantityPerType} containers
            </Button>
          </div>
        </div>
      </Modal.Content>
    </Modal>
  )
}
