import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Divider from '@mui/material/Divider'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import { useState } from 'react'

import Button from '@/components/Button'
import Input from '@/components/Input'

import Manifest from './models/Manifest'
import { useDropForCourierMutation } from './useDropForCourierMutation'

interface ManifestCardProps {
  manifest: Manifest
  onDropped: () => void
}

export default function ManifestCard({ manifest, onDropped }: ManifestCardProps) {
  const dropForCourierMutation = useDropForCourierMutation()
  const [manifestReferenceId, setManifestReferenceId] = useState('')

  async function handleSetAsDropped() {
    dropForCourierMutation.mutate(
      { courierUmbrellaName: manifest.courier.slug, manifestReferenceId: manifestReferenceId },
      { onSuccess: onDropped },
    )
  }

  return (
    <Card>
      <CardContent>
        <Stack spacing={1}>
          <Stack direction="row" justifyContent="space-between">
            <Typography variant="h3" component="span">
              {manifest.courier.displayName}
            </Typography>
            <div>
              <span>
                <Typography fontWeight="bold" component="span">
                  {manifest.packagesCount}
                </Typography>
                <span> pcs</span>
              </span>
              {' / '}
              <span>
                <Typography fontWeight="bold" component="span">
                  {manifest.packagesWeight.value}
                </Typography>{' '}
                {manifest.packagesWeight.unit}
              </span>
            </div>
          </Stack>
          <Divider light />
          {manifest.requiresManifestId && (
            <Input
              disabled={manifest.processingDrop}
              placeholder="Manifest Reference ID"
              value={manifestReferenceId}
              onChange={(e) => setManifestReferenceId(e.target.value)}
            />
          )}
          <Button
            variant="contained"
            color="primary"
            onClick={handleSetAsDropped}
            disabled={
              (manifest.requiresManifestId && !manifestReferenceId) || manifest.processingDrop
            }
            loading={dropForCourierMutation.isLoading}
          >
            set as dropped
          </Button>
          {manifest.processingDrop && (
            <div className="text-lg">
              Drop in progress. Please wait before dropping more packages.
            </div>
          )}
        </Stack>
      </CardContent>
    </Card>
  )
}
