import Chance from 'chance'

import DeletePrintQueue from '@/ViewContainers/models/DeletePrintQueue'

const chance = new Chance()

export const deletePrintQueueStub = (): DeletePrintQueue => ({
  printQueue: {
    id: chance.integer(),
    status: chance.string(),
    createdAt: chance.string(),
    updatedAt: chance.string(),
    printQueueItems: [
      {
        id: chance.integer(),
        printQueueId: chance.integer(),
        title: chance.string(),
        barcodeText: chance.string(),
        barcodeLargeText: chance.string(),
        status: chance.string(),
        createdAt: chance.string(),
        updatedAt: chance.string(),
      },
      {
        id: chance.integer(),
        printQueueId: chance.integer(),
        title: chance.string(),
        barcodeText: chance.string(),
        barcodeLargeText: chance.string(),
        status: chance.string(),
        createdAt: chance.string(),
        updatedAt: chance.string(),
      },
    ],
  },
})
