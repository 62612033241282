import { useMutation } from '@tanstack/react-query'

import { toastApiError } from '@/components/Toastify'
import { useGateways } from '@/contexts/gateways'
import { CreateMultipleBins } from '../models/Bin'

interface CreateMultipleBinsMutationParams {
  bins: CreateMultipleBins[]
}

export function useCreateMultipleBinsMutation() {
  const { binsGateway } = useGateways()

  return useMutation({
    mutationFn: ({ bins }: CreateMultipleBinsMutationParams) => binsGateway.createBinsBatch(bins),
    onError: (reason) => toastApiError(reason),
  })
}
