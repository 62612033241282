import Chance from 'chance'

import BinType from '@/ViewContainers/models/BinType'
import PrintQueue from '@/ViewContainers/models/PrintQueue'

const chance = new Chance()

export const printQueueStub = (): PrintQueue => ({
  id: chance.integer(),
  status: 'pending',
  createdAt: chance.date().toISOString(),
  printQueueItems: Array.from({ length: chance.integer({ min: 1, max: 5 }) }, () => ({
    id: chance.integer(),
    printQueueId: chance.integer(),
    title: chance.sentence(),
    status: 'pending',
    barcodeText: chance.string(),
    barcodeLargeText: chance.string(),
    barcodeNote: chance.sentence(),
  })),
})
