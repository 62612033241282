import Box from '@mui/material/Box'
import MuiButton, { ButtonProps as MuiButtonProps } from '@mui/material/Button'
import { useTheme } from '@mui/material/styles'

import Pulse from './Pulse'

interface TabProps {
  title: string
  selected?: boolean
  onTabChange?: () => void
}

export default function Tab({ title, selected, onTabChange }: TabProps) {
  return (
    <div
      onClick={onTabChange}
      className={`cursor-pointer p-3 text-lg font-bold ${
        selected ? 'border-b-4 border-teal-500 text-ink-900' : 'text-ink-500'
      }`}
    >
      {title}
    </div>
  )
}
