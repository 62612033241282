import CloseIcon from '@mui/icons-material/Close'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import React from 'react'

import COLORS from '@/colors'
import Button from '@/components/Button'
import LabelProcessingIcon from '@/components/icons/LabelProcessingIcon'
import PackingIcon from '@/components/icons/PackingIcon'

import { ScanResult } from './gateways/api/models/Package'
import useReprintLabelQuery from './useReprintLabelQuery'

interface ResultStyle {
  bgColor: string
  icon: React.ReactNode
}

type ScanResultStyled = Exclude<ScanResult, 'package_not_found'>

const style: Record<ScanResultStyled, ResultStyle> = {
  label_printing: {
    bgColor: COLORS.greenLight,
    icon: <LabelProcessingIcon sx={{ fontSize: 120 }} htmlColor={COLORS.greenDark} />,
  },
  inspection: {
    bgColor: COLORS.yellowLight,
    icon: <PackingIcon sx={{ fontSize: 120 }} htmlColor={COLORS.yellowDarkest} />,
  },
  exception: {
    bgColor: COLORS.blueLight,
    icon: <CloseIcon sx={{ fontSize: 120 }} htmlColor={COLORS.blueDarkest} />,
  },
  no_dims_weight: {
    bgColor: COLORS.redLight,
    icon: <CloseIcon sx={{ fontSize: 120 }} htmlColor={COLORS.redDarkest} />,
  },
  rescan: {
    bgColor: COLORS.purpleDark,
    icon: <CloseIcon sx={{ fontSize: 120 }} htmlColor={COLORS.blueDarkest} />,
  },
  high_value: {
    bgColor: COLORS.redNormal,
    icon: <PackingIcon sx={{ fontSize: 120 }} htmlColor={COLORS.redDarkest} />,
  },
  forced_inspection: {
    bgColor: COLORS.brownLight,
    icon: <PackingIcon sx={{ fontSize: 120 }} htmlColor={COLORS.brownDark} />,
  },
  single_parcel_xray: {
    bgColor: COLORS.yellowNormal,
    icon: <PackingIcon sx={{ fontSize: 120 }} htmlColor={COLORS.brownDarkest} />,
  },
  snad_package_tag: {
    bgColor: COLORS.redLight,
    icon: <PackingIcon sx={{ fontSize: 120 }} htmlColor={COLORS.redDarkest} />,
  },
}

interface LabelProcessingResultProps {
  label: string
  scanResult: ScanResultStyled
  packageId: string
}

export default function LabelProcessingResult({
  label,
  scanResult,
  packageId,
}: LabelProcessingResultProps) {
  const { refetch, isFetching } = useReprintLabelQuery(packageId)
  return (
    <Stack
      alignItems="center"
      justifyContent="center"
      bgcolor={style[scanResult].bgColor}
      width={600}
      height={360}
      borderRadius={2}
      spacing={2}
    >
      {style[scanResult].icon}
      <Typography variant="h1" fontSize={45} fontWeight="bold" textAlign="center">
        {label}
      </Typography>
      {scanResult === 'label_printing' && (
        <Button
          onClick={() => refetch()}
          variant="contained"
          color="secondary"
          sx={{ height: 80, minWidth: 140 }}
          loading={isFetching}
        >
          reprint
        </Button>
      )}
    </Stack>
  )
}
