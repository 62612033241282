import dayjs from 'dayjs'
import { Button, Modal } from 'easyship-components'
import { toast } from 'react-toastify'

import { useDeletePrintQueueMutation } from './hooks/useDeletePrintQueueMutation'
import PrintQueue from './models/PrintQueue'

interface ConfirmDeletePrintQueProps {
  open: boolean
  onClose: () => void
  printQueue?: PrintQueue
}

export default function ConfirmDeletePrintQueueDialog({
  open,
  onClose,
  printQueue,
}: ConfirmDeletePrintQueProps) {
  const deletePrintQueueMutation = useDeletePrintQueueMutation()

  function handleRemoveQueue() {
    if (printQueue?.id) {
      const queueId = printQueue.id
      deletePrintQueueMutation.mutate(
        { queueId },
        {
          onSuccess: () => {
            toast.success(`${printQueue.printQueueItems.length} label deleted`)
          },
          onSettled: () =>  onClose()
        }
      )
    }
  }

  return (
    <Modal
      open={open}
      title="Delete labels"
      onClose={onClose}
      role="delete-labels-dialog"
      dismissOnClickBackdrop
      dismissOnEscape
    >
      <Modal.Header title="Delete labels" closeButtonAriaLabel="Close modal" />
      <Modal.Content className="w-[90vw] px-12 py-10 lg:w-[520px]">
        <div>
          Are you sure you want to delete this{' '}
          <span className="font-bold">
            {dayjs(printQueue?.createdAt).format('DD MMM YYYY, h:mm A')}
          </span>{' '}
          batch of <span className="font-bold">{printQueue?.printQueueItems.length}</span> labels
          from the print queue?
        </div>
      </Modal.Content>
      <Modal.Footer className="flex justify-between px-12 py-5">
        <Button
          flat
          onClick={onClose}
          className="w-[72px]"
          type="button"
          name="cancel"
          aria-label="cancel"
        >
          cancel
        </Button>
        <Button
          color="danger"
          onClick={handleRemoveQueue}
          className="w-[126px]"
          type="button"
          name="delete"
          aria-label="delete"
        >
          delete
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
