import BatteryChargingFullIcon from '@mui/icons-material/BatteryChargingFull'
import DoDisturbIcon from '@mui/icons-material/DoDisturb'
import ErrorIcon from '@mui/icons-material/Error'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import MuiCollapse from '@mui/material/Collapse'
import Divider from '@mui/material/Divider'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import { ChevronDownward, ChevronUpward } from 'easyship-components/icons'
import { useRef, useState } from 'react'

import { useFeatureFlags } from '@/contexts/featureFlags'

import { usePackage } from '../PackageProvider'
import BatteryForm, { BatteryEditFormData, BatteryFormData, ResetFormRef } from './BatteryForm'
import YesNoSelect from './YesNoSelect'

interface CollapseHeaderProps {
  title: string
  hasBattery: boolean | undefined
  formCompleted: boolean
  onItemCollapse: () => void
  open: boolean
}

function ItemCollapseHeader({
  title,
  hasBattery,
  formCompleted,
  onItemCollapse,
  open,
}: CollapseHeaderProps) {
  return (
    <div
      className={formCompleted ? 'cursor-default' : 'cursor-pointer'}
      onClick={formCompleted ? () => null : onItemCollapse}
    >
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <span>{title}</span>
        <Stack direction="row">
          {typeof hasBattery === 'boolean' && (
            <Box
              data-testid={hasBattery ? 'has-battery' : 'has-no-battery'}
              position="relative"
              display="flex"
              justifyContent="center"
              alignItems="center"
              color={hasBattery ? 'initial' : 'sky.dark'}
              width={50}
            >
              <BatteryChargingFullIcon sx={{ position: 'absolute' }} />
              {!hasBattery && <DoDisturbIcon fontSize="large" />}
            </Box>
          )}
          <div className="flex items-center">
            <div>
              <Typography variant="h5">Status</Typography>
              {formCompleted ? 'Completed' : 'Incomplete'}
            </div>
            <div className="ml-2">{open ? <ChevronUpward /> : <ChevronDownward />}</div>
          </div>
        </Stack>
      </Stack>
    </div>
  )
}

interface BatteryInspectionEditFormData {
  batteryFormsData: BatteryFormData[]
  doesPreventAccident: boolean | undefined
  isPackagedCardboard: boolean | undefined
}

export interface BatteryInspectionFormData {
  batteryFormsData: BatteryFormData[]
  doesPreventAccident: boolean | undefined
  isPackagedCardboard?: boolean | undefined
}

export default function BatteryInspection() {
  const { pack, currentInspection, submitBatteryInspection, classifyBattery } = usePackage()
  const [itemCollapseIndex, setItemCollapseIndex] = useState<number | null>(0)
  const [batteryInspectionFormData, setBatteryInspectionFormData] =
    useState<BatteryInspectionEditFormData>({
      batteryFormsData: [],
      doesPreventAccident: undefined,
      isPackagedCardboard: undefined,
    })
  const [itemsBatteryPresent, setItemsBatteryPresent] = useState<(boolean | undefined)[]>([])

   // handleResetForm is not used, but it can be added back
  const batteryFormRef = useRef<ResetFormRef>(null)
  const handleResetForm = () => {
    if (batteryFormRef.current) {
      setItemCollapseIndex(0)
      batteryFormRef.current.reset();
      setBatteryInspectionFormData({
        batteryFormsData: [],
        doesPreventAccident: undefined,
        isPackagedCardboard: undefined,
      })
    }
  }

  const { isFeatureEnabled } = useFeatureFlags()
  const isM3Enabled = isFeatureEnabled('EX2_795_M3_repacking')

  function handleFormChange(index: number, formData: BatteryEditFormData) {
    const newData = [...itemsBatteryPresent]
    newData[index] = formData.batteryPresent
    setItemsBatteryPresent(newData)
  }

  function handleFormSubmit(index: number, formData: BatteryFormData) {
    const newData = [...batteryInspectionFormData.batteryFormsData]
    newData[index] = formData
    setBatteryInspectionFormData({ ...batteryInspectionFormData, batteryFormsData: newData })
    setItemCollapseIndex(index === pack.items.length ? null : index + 1)
  }

  const openFormIndex = batteryInspectionFormData.batteryFormsData.length
  const areAllItemFormsCompleted = openFormIndex === pack.items.length
  const hasPackageBattery = batteryInspectionFormData.batteryFormsData.some(
    (item) => item.batteryPresent,
  )
  const isPreventAccidentFieldSet = batteryInspectionFormData.doesPreventAccident !== undefined
  const isPackagedCardboardFieldSet = batteryInspectionFormData.isPackagedCardboard !== undefined

  return (
    <div>
      <Stack direction="row" spacing={1}>
        <ErrorIcon color="error" />
        <Typography variant="h1">Complete Battery Handling Form</Typography>
      </Stack>
      <Divider sx={{ ml: 3, mt: 2 }} />
      <Stack divider={<Divider />} spacing={2} ml={3} mt={2}>
        {pack.items.map((item, index) => (
          <Stack key={index}>
            <ItemCollapseHeader
              open={index === itemCollapseIndex}
              onItemCollapse={() => setItemCollapseIndex((prev) => (prev === index ? null : index))}
              title={`#${index + 1} ${item.description}`}
              hasBattery={itemsBatteryPresent[index]}
              formCompleted={!!batteryInspectionFormData.batteryFormsData[index]}
            />
            <MuiCollapse in={index === itemCollapseIndex}>
              <BatteryForm
                initialFormData={{ itemId: item.sku, itemDescription: item.description }}
                onChange={(formData) => handleFormChange(index, formData)}
                onSubmit={(formData) => handleFormSubmit(index, formData)}
                ref={batteryFormRef}
              />
            </MuiCollapse>
          </Stack>
        ))}
        {areAllItemFormsCompleted && (
          <>
            {hasPackageBattery && (
              <YesNoSelect
                label="Is the parcel equipped with an effective means of preventing accidental activation?"
                value={batteryInspectionFormData.doesPreventAccident}
                onChange={(_, v) =>
                  setBatteryInspectionFormData({
                    ...batteryInspectionFormData,
                    doesPreventAccident: v,
                  })
                }
              />
            )}
            {isM3Enabled && hasPackageBattery && (
              <YesNoSelect
                label="Is the parcel packaged in cardboard?"
                value={batteryInspectionFormData.isPackagedCardboard}
                onChange={(_, v) =>
                  setBatteryInspectionFormData({
                    ...batteryInspectionFormData,
                    isPackagedCardboard: v,
                  })
                }
              />
            )}
            {isM3Enabled ? (
              <Stack direction="row" spacing={2}>
                {/* hide this for now, might need this later */}
                {/* <Button
                  onClick={handleResetForm}
                  variant="contained"
                  color="secondary"
                  sx={{ height: 45 }}
                  fullWidth
                >
                  reset
                </Button> */}
                <Button
                  onClick={() => {
                    currentInspection
                      ? classifyBattery(batteryInspectionFormData as BatteryInspectionFormData)
                      : submitBatteryInspection(
                          batteryInspectionFormData as BatteryInspectionFormData,
                        )
                  }}
                  variant="contained"
                  sx={{ height: 45 }}
                  disabled={typeof batteryInspectionFormData.isPackagedCardboard !== 'boolean'}
                  fullWidth
                >
                  next
                </Button>
              </Stack>
            ) : (
              <Button
                variant="contained"
                sx={{ height: 45 }}
                onClick={() =>
                  currentInspection
                    ? classifyBattery(batteryInspectionFormData as BatteryInspectionFormData)
                    : submitBatteryInspection(
                        batteryInspectionFormData as BatteryInspectionFormData,
                      )
                }
                disabled={
                  hasPackageBattery && !isPreventAccidentFieldSet && !isPackagedCardboardFieldSet
                }
              >
                submit
              </Button>
            )}
          </>
        )}
      </Stack>
    </div>
  )
}
