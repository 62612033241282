import Chance from 'chance'

import { PrintedQueue } from '@/ViewContainers/models/PrintedQueue'

const chance = new Chance()
export const printedQueueStub = (): PrintedQueue => ({
  id: chance.integer(),
  status: chance.string(),
  createdAt: chance.string(),
  updatedAt: chance.string(),
  printQueueItems: [
    {
      id: chance.integer(),
      printQueueId: chance.integer(),
      title: chance.string(),
      barcodeText: chance.string(),
      barcodeLargeText: chance.string(),
      status: chance.string(),
      createdAt: chance.string(),
      updatedAt: chance.string(),
    },
    {
      id: chance.integer(),
      printQueueId: chance.integer(),
      title: chance.string(),
      barcodeText: chance.string(),
      barcodeLargeText: chance.string(),
      status: chance.string(),
      createdAt: chance.string(),
      updatedAt: chance.string(),
    },
  ],
})
