import Card from '@mui/material/Card'
import CardActionArea from '@mui/material/CardActionArea'
import CardContent from '@mui/material/CardContent'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import dayjs from 'dayjs'
import { useMemo } from 'react'
import { useNavigate } from 'react-router-dom'

import { Stat, StatLabel, StatValue } from '@/components/Stat'
import { toastApiError } from '@/components/Toastify'
import { ProcessStage } from '@/ViewContainers/models/Bin'
import useBins from '@/core/useBins'

import { NavigationStateParams as BinDetailsPageNavigationStateParams } from './BinDetailsPage/BinDetailsPage'

interface ContainerProcessStagesProps {
  data: ProcessStage[]
}

export default function ContainerProcessStages({ data }: ContainerProcessStagesProps) {
  const navigate = useNavigate()
  const { getBinDetailsByBarcode } = useBins()
  const processStages = useMemo<ProcessStage[]>(() => getProcessStagesBinsSorted(data), [data])

  function handleBinCardClick(barcode: string) {
    getBinDetailsByBarcode(barcode)
      .then((binDetails) => {
        const navigationState: BinDetailsPageNavigationStateParams = {
          binDetails,
        }

        navigate('/bin-details', { state: navigationState })
      })
      .catch(toastApiError)
  }

  function getProcessStagesBinsSorted(processStages: ProcessStage[]): ProcessStage[] {
    return processStages.map((processStage) => ({
      ...processStage,
      bins: processStage.bins.sort((a, b) =>
        dayjs(a.oldestPackageAddedDateTime).diff(dayjs(b.oldestPackageAddedDateTime)),
      ),
    }))
  }

  return (
    <Stack direction="row" spacing={4}>
      {processStages.map((processStage) => (
        <Stack key={processStage.name} spacing={3} width="calc(100% / 4)">
          <Typography variant="h4">
            {processStage.name} ({processStage.bins.length})
          </Typography>
          {processStage.bins.map((bin) => (
            <Card role="listitem" key={bin.id} onClick={() => handleBinCardClick(bin.barcode)}>
              <CardActionArea>
                <CardContent>
                  <Stack spacing={1}>
                    <Typography variant="h5">{bin.displayName}</Typography>
                    <Stat>
                      <StatLabel variant="subtitle2">Bin ID</StatLabel>
                      <StatValue variant="h3">{bin.name}</StatValue>
                    </Stat>
                    <Stat>
                      <StatLabel variant="subtitle2">Location</StatLabel>
                      <StatValue variant="h3">{bin.floorLocation.name}</StatValue>
                    </Stat>
                    {bin.droppedAt ? (
                      <Stat>
                        <StatLabel variant="subtitle2">Dropped at</StatLabel>
                        <StatValue variant="h3">
                          {bin.droppedAt ? dayjs(bin.droppedAt).fromNow() : '-'}
                        </StatValue>
                      </Stat>
                    ) : (
                      <Stat>
                        <StatLabel variant="subtitle2">Oldest Package Received</StatLabel>
                        <StatValue variant="h3">
                          {bin.oldestPackageAddedDateTime
                            ? dayjs(bin.oldestPackageAddedDateTime).fromNow()
                            : '-'}
                        </StatValue>
                      </Stat>
                    )}
                  </Stack>
                </CardContent>
              </CardActionArea>
            </Card>
          ))}
        </Stack>
      ))}
    </Stack>
  )
}
