import Bin from '@/ViewContainers/models/Bin'

import { BinData } from '../models/Bin'

export function mapFromApi(bin: BinData): Bin {
  return {
    id: bin.id,
    name: bin.name,
    displayName: bin.display_name,
    processStage: bin.process_stage,
    floorLocation: bin.floor_area,
    sizeName: bin.sort_size.name,
    typeName: bin.sort_type.name,
    barcode: bin.barcode,
    oldestPackageAddedDateTime: bin.oldest_package_added_at,
    droppedAt: bin.dropped_at,
  }
}
