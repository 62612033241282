import { useMutation } from '@tanstack/react-query'

import { toastApiError } from '@/components/Toastify'
import { useGateways } from '@/contexts/gateways'

interface DeletePrintQueueMutationParams {
  queueId: number
}

export function useDeletePrintQueueMutation() {
  const { binsGateway } = useGateways()

  return useMutation({
    mutationFn: ({ queueId }: DeletePrintQueueMutationParams) =>
      binsGateway.deletePrintQueue(queueId),
    onError: (reason) => toastApiError(reason),
  })
}
