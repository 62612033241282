import { toast, ToastContainer, ToastOptions } from 'react-toastify'

import { ApiError, isApiError } from '@/core/models/ApiError'
import 'react-toastify/dist/ReactToastify.min.css'

function getErrorMessage(error: ApiError['error']): string {
  const hasErrorDetails = error.detail.length > 0

  if (hasErrorDetails) return error.detail.join(' ')
  if (error.message) return error.message
  return error.code
}

export function toastApiError(reason: unknown, toastOptions?: ToastOptions) {
  if (isApiError(reason)) {
    toast.error(getErrorMessage(reason.error), { autoClose: 5000, ...toastOptions })
  } else {
    // unhandled error
    console.error(reason)
  }
}

export function Toastify() {
  return (
    <ToastContainer
      position="top-right"
      autoClose={3000}
      draggable={false}
      pauseOnFocusLoss
      pauseOnHover
      theme="light"
    />
  )
}
