import { useQuery } from '@tanstack/react-query'

import { useGateways } from '@/contexts/gateways'
import { toastApiError } from '@/components/Toastify'

export default function useReprintLabelQuery(packageId: string) {
  const { labelProcessingGateway } = useGateways()

  const query = useQuery({
    queryKey: ['reprintLabel'],
    queryFn: () => labelProcessingGateway.reprintLabel(packageId),
    enabled: false,
    onError: (reason) => toastApiError(reason),
  })

  return query
}
