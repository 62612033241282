import axios from 'axios'

import Manifest from '@/Manifest/models/Manifest'
import ManifestGateway from '@/Manifest/ports/manifest'

import { ManifestsResponse } from './models/Manifest'

function getAllPending(): Promise<Manifest[]> {
  return axios.get<ManifestsResponse>('/v1/pending_manifests').then(({ data }) =>
    data.pending_manifests.map<Manifest>((manifest) => ({
      courier: {
        displayName: manifest.courier_display_name,
        slug: manifest.courier_slug,
      },
      packagesWeight: { value: manifest.packages_weight, unit: manifest.packages_weight_unit },
      packagesCount: manifest.packages_count,
      requiresManifestId: manifest.request_manifest_id,
      processingDrop: manifest.processing_drop,
    })),
  )
}

function dropForCourier(courierUmbrellaName: string, manifestReferenceId: string): Promise<void> {
  return axios.post(`/v1/drops`, {
    manifest_reference_id: manifestReferenceId,
    courier_slug: courierUmbrellaName,
  })
}

export default function createApiManifestGateway(): ManifestGateway {
  return { getAllPending, dropForCourier }
}
