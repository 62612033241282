import Button from '@mui/material/Button'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import { Input } from 'easyship-components'
import { useState } from 'react'

export default function ZendeskForm({
  onZendeskCompleted,
  isLoading = false,
}: {
  onZendeskCompleted: (ticketId: string) => void
  isLoading?: boolean
}) {
  const [zendeskTicketId, setZendeskTicketId] = useState('')

  return (
    <form>
      <Stack spacing={3}>
        <Input
          label={<Typography variant="h1">Zendesk Ticket ID </Typography>}
          value={zendeskTicketId}
          onChange={(e) => setZendeskTicketId(e.target.value)}
          placeholder="Ticket ID"
          autoFocus
          type="number"
          className="no-arrows"
          onWheel={(e) => (e.target as HTMLElement).blur()}
        />
        <Stack alignItems="center">
          <Button
            variant="contained"
            color="secondary"
            sx={{ height: 80, width: 270 }}
            disabled={!zendeskTicketId || isLoading}
            onClick={() => onZendeskCompleted(zendeskTicketId)}
          >
            zendesk ticket completed
          </Button>
        </Stack>
      </Stack>
    </form>
  )
}
