import Chance from 'chance'

import Manifest from '@/Manifest/models/Manifest'

const chance = new Chance()

export const manifestStub = (): Manifest => ({
  courier: {
    displayName: chance.string(),
    slug: chance.string(),
  },
  packagesCount: chance.integer({ min: 1, max: 999 }),
  packagesWeight: {
    value: chance.floating({ min: 0, max: 100, fixed: 2 }),
    unit: 'lb',
  },
  requiresManifestId: chance.bool(),
  processingDrop: false,
})
