import { useMutation } from '@tanstack/react-query'

import { toastApiError } from '@/components/Toastify'
import { useGateways } from '@/contexts/gateways'
import { CreateMultipleBins } from '../models/Bin'

interface RetryPrintItemMutationParams {
  queueId: number
  queueItemId: number
}

export function useRetryPrintItemMutation() {
  const { binsGateway } = useGateways()

  return useMutation({
    mutationFn: ({ queueId, queueItemId }: RetryPrintItemMutationParams) =>
      binsGateway.RetryPrintItem(queueId, queueItemId),
    onError: (reason) => toastApiError(reason),
  })
}
