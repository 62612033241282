import { createContext, useContext, useState } from 'react'
import { toast } from 'react-toastify'

import { toastApiError } from '@/components/Toastify'
import { useGateways } from '@/contexts/gateways'
import BinDetails from '@/ViewContainers/models/BinDetails'
import useBinFloorLocationsQuery from '@/hooks/useBinFloorLocationsQuery'

import { MoveBinFormData } from './MoveBinDialog'

interface BinContextData {
  bin: BinDetails
  moveBin(formData: MoveBinFormData): Promise<void>
  printBarcode(): Promise<void>
  removePackages(barcodes: string[]): Promise<void>
  markForInspection(barcodes: string[]): Promise<void>
  markXRayAsCompleted(): Promise<void>
}

const BinContext = createContext<BinContextData | undefined>(undefined)

interface BinProviderProps {
  initialBin: BinDetails
  children: React.ReactNode
}

function BinProvider({ initialBin, children }: BinProviderProps) {
  const { binsGateway } = useGateways()
  const [bin, setBin] = useState(initialBin)
  const { data: floorLocations = [] } = useBinFloorLocationsQuery()

  async function moveBin(formData: MoveBinFormData): Promise<void> {
    try {
      await binsGateway.moveBin(bin.id, formData).then(() => {
        const floorLocationFound = floorLocations.find(
          (floorLocation) => floorLocation.id === formData.floorLocationId,
        )

        if (floorLocationFound) {
          setBin({
            ...bin,
            floorLocation: floorLocationFound,
          })
        }
      })
    } catch (reason) {
      toastApiError(reason)
      throw reason
    }
  }

  async function printBarcode(): Promise<void> {
    await binsGateway
      .printBarcode(bin.id)
      .then(() => {
        toast.success(`Barcode printed successfully`)
      })
      .catch((err) => toastApiError(err))
  }

  async function removePackages(barcodes: string[]): Promise<void> {
    await binsGateway.removePackages(bin.id, barcodes).then(({ succeededPackageIds }) =>
      setBin((bin) => ({
        ...bin,
        packages: bin.packages.filter((pack) => !succeededPackageIds.includes(pack.id)),
      })),
    )
  }

  async function markForInspection(barcodes: string[]): Promise<void> {
    await binsGateway.markForInspection(bin.id, barcodes, 'xray')
  }

  async function markXRayAsCompleted(): Promise<void> {
    await binsGateway.markXRayAsCompleted(bin.id)
  }

  const data: BinContextData = {
    bin,
    moveBin,
    printBarcode,
    removePackages,
    markForInspection,
    markXRayAsCompleted,
  }

  return <BinContext.Provider value={data}>{children}</BinContext.Provider>
}

function useBin() {
  const binContext = useContext(BinContext)

  if (binContext === undefined) {
    throw new Error('useBin must be used within a BinProvider')
  }
  return binContext
}

export { BinProvider, useBin }
